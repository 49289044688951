
import Vue from 'vue';

import IcArrowRightLong from '@/assets/svg/ic_arrow_right_long.svg?inline';
import { ELocalStorageItem } from '@/domain/core/LocalStorageItem.enum';
import { ERouteName } from '@/domain/core/Routes.enum';
import { Product, ProductIdentifier } from '@/domain/product/types';
import { EGtmEcommerceListName } from '@/infrastructure/externalServices/gtm/DataLayer.enum';
import { EFeatureFlags } from '@/infrastructure/externalServices/launchDarkly/types';

export default Vue.extend({
  name: 'CarouselProductTailoredSuggestions',
  components: {
    IcArrowRightLong,
  },
  data() {
    return {
      product: {} as Product,
      products: [] as Product[],
      trackingId: EGtmEcommerceListName.SimilarProducts,
    };
  },
  fetchOnServer: false,
  async fetch(): Promise<void> {
    const [lastItemViewed] = this.lastViewedProductSkus;
    const productIdentifier: ProductIdentifier | null = lastItemViewed || null;

    if (productIdentifier) {
      try {
        const [product, similarProducts] = await Promise.all([
          this.$repositories.product.getProductLite(productIdentifier),
          this.$repositories.product.getSimilarProducts(productIdentifier, {
            onlyPriceReduced: this.shouldEnableSalesFeatures,
          }),
        ]);

        this.product = product;
        this.products = similarProducts.items;
      } catch (err) {
        this.$errorMonitor.report(err, 'error');

        throw err;
      }
    }
  },
  computed: {
    hasProducts(): boolean {
      return !!this.products?.length;
    },
    lastViewedProductSkus(): Product['sku'][] {
      let parsedItems: Product['sku'][] = [];

      if (process.client) {
        const rawLastItemsViewed = localStorage.getItem(ELocalStorageItem.StoredItemsViewed);

        if (rawLastItemsViewed) {
          parsedItems = rawLastItemsViewed ? JSON.parse(rawLastItemsViewed) : [];
        }
      }

      return parsedItems;
    },
    linkToSelection(): string {
      return this.$router.resolve({
        name: ERouteName.ProductSimilars,
        params: {
          sku: this.product.sku,
          slug: this.product.slug,
        },
      }).href;
    },
    shouldEnableSalesFeatures(): boolean {
      return this.$accessor?.featureFlags?.list?.[EFeatureFlags.Sales];
    },
  },
});
