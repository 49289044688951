
import Vue, { PropType } from 'vue';

import { ELanguage } from '@/domain/core/Language.enum';
import { EBlockType, EditoBlock } from '@/domain/edito/types';
import { EGtmEventHomepage } from '@/infrastructure/externalServices/gtm/DataLayer.enum';
import { EditoBlocksClickEventPayload } from '@/infrastructure/externalServices/gtm/edito/EventPayload.interface';
import { _isCurrentDateBetweenTwoDates } from '@/utilities/dates/isCurrentDateBetweenTwoDates';

export default Vue.extend({
  name: 'EditoBlocks',
  props: {
    blocks: {
      type: Array as PropType<EditoBlock[]>,
      required: true,
    },
    isUserPro: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      EBlockType: Object.freeze(EBlockType),
      operation: {
        start: '2024-02-07T00:00:00',
        end: '2024-02-11T23:59:59',
      },
    };
  },
  computed: {
    isUserAuthenticated(): boolean {
      return this.$accessor.user.authenticated;
    },
    shouldDisplayAppPromoBanner(): boolean {
      const { end, start } = this.operation;

      return this.$i18n.locale === ELanguage.FR && _isCurrentDateBetweenTwoDates({ end, start });
    },
  },
  methods: {
    handleTracking(block: EditoBlock, eventPayload: EditoBlocksClickEventPayload) {
      const { type, indexByBlockType } = block;

      let event = null;
      const payload: EditoBlocksClickEventPayload = { ...eventPayload };

      if (type === EBlockType.Banner) {
        event = EGtmEventHomepage.ClickBanner;
        payload.block = indexByBlockType;
      } else if (type === EBlockType.MainBlock) {
        event = EGtmEventHomepage.ClickEditoMain;
        payload.block = indexByBlockType;
      } else if (type === EBlockType.Slider) {
        event = EGtmEventHomepage.ClickCarousel;
      }

      if (event) {
        this.$gtm.push({
          event,
          ...payload,
        });
      }
    },
  },
});
